<template>
  <BSelectWithValidation
    v-if="visible"
    :id="`prelimInput_${field.identifier}`"
    rules="required"
    :label="field.label"
    v-model="selectedValue"
  >
    <option
      v-for="(iv, index) in field.inputValues"
      :id="`prelimInput_${field.identifier}_${index}`"
      :value="iv.value"
      :key="index"
    >
      {{ iv.text }}
    </option>
  </BSelectWithValidation>
</template>

<script>
export default {
  name: "PreliminarySelectQuestion",
  components: {
    BSelectWithValidation: () =>
      import("@/components/BSelectWithValidation.vue"),
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: null,
    };
  },
  mounted() {
    this.selectedValue =
      this.$store.getters[
        this.mortgage
          ? "mortgagePreliminaryAssessment/answer"
          : "preliminaryAssessment/answer"
      ](this.field.identifier) || null;
    this.$watch("selectedValue", this.valueWatcher);
  },
  methods: {
    valueWatcher() {
      this.$store.dispatch(
        this.mortgage
          ? "mortgagePreliminaryAssessment/addData"
          : "preliminaryAssessment/addData",
        {
          question: this.field.identifier,
          answer: this.selectedValue,
        }
      );
    },
  },
};
</script>

<style scoped></style>
