import { render, staticRenderFns } from "./PreliminarySelectQuestion.vue?vue&type=template&id=9da9a402&scoped=true&"
import script from "./PreliminarySelectQuestion.vue?vue&type=script&lang=js&"
export * from "./PreliminarySelectQuestion.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9da9a402",
  null
  
)

export default component.exports